<template>
	<div class="ele-body">
		<el-tabs v-model="activeName">
			<el-tab-pane label="商家自营活动" name="first">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
						<el-form-item label="活动类型:" label-width="76px">
							<el-select v-model="table.where.type" placeholder="全部" clearable class="input150">
								<el-option v-for="option in storeactivityType" :value="option.id" :key="option.id"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="商家姓名:" label-width="76px" class="w-input">
							<el-input v-model="table.where.name" placeholder="请输入商家姓名" clearable />
						</el-form-item>
						<el-form-item label="商家手机号:" label-width="100px" style="width:260px">
							<el-input v-model="table.where.phone" placeholder="请输入商家手机号" clearable />
						</el-form-item>
						<el-form-item label="店铺名称:" label-width="76px" class="w-input">
							<el-input v-model="table.where.store_name" placeholder="请输入店铺名称" clearable />
						</el-form-item>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table.where.cid"
								@change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="left-btn">
							<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
								class="ele-btn-icon search-margin">搜索</el-button>
							<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="120" />
							<el-table-column prop="phone" label="商家手机号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="image" label="活动封面图" show-overflow-tooltip min-width="100">
								<template slot-scope="{row}">
									<el-image style="width:25px;height:25px;" :src="row.image" fit="cover" :preview-src-list="[row.image]">
										<div slot="error" class="image-slot">
											<i class="el-icon-picture-outline"></i>
										</div>
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="name" label="活动名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="type" label="活动类型" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.type == 1 ? '零钱助力活动' :'优惠满减活动' }}</template>
							</el-table-column>
							<el-table-column prop="start_time" label="开始时间" show-overflow-tooltip min-width="170" />
							<el-table-column prop="end_time" label="结束时间" show-overflow-tooltip min-width="170" />
							<el-table-column prop="day" label="领取有效时间(天)" show-overflow-tooltip min-width="140" />
							<el-table-column prop="views_num" label="浏览次数(次)" show-overflow-tooltip min-width="100" />
							<el-table-column prop="status_name" label="状态" show-overflow-tooltip min-width="100" />
							<el-table-column label="操作" width="300px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link icon="el-icon-view" @click="handlecheckSelf(row)" type="primary"
										:underline="false">查看详情</el-link>
									<el-link icon="el-icon-view" @click="handlecheckSelfGet(row)" type="primary"
										:underline="false" v-if="permission.includes('sys:merchants_prop:view_list')">查看领取列表</el-link>
									<el-dropdown @command="command => dropClick(command,row)">
										<span class="el-dropdown-link" style="margin-left: 10px;">
											<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item icon="el-icon-delete" command="activityDelete" v-if="permission.includes('sys:merchants_prop:delete')">删除
											</el-dropdown-item>
											<el-dropdown-item v-if="row.status !== 2 && permission.includes('sys:merchants_prop:underline')" icon="el-icon-user-solid" command="underline">下线
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-card>

				<!-- 对话框：查看领取列表 -->
				<el-dialog v-dialogDrag title="查看领取列表" :visible.sync="dialogTableVisibleSelfGet" @closed="table5.where={activity_id: 0}" width="1200px" :destroy-on-close="true" :lock-scroll="false">
						<el-card shadow="never">
							<!-- 数据表格 -->
							<ele-data-table ref="table5" :config="table5" :choose.sync="choose" height="calc(100vh - 315px)"
								:stripe=true highlight-current-row>
								<template slot-scope="{index}">
									<el-table-column type="selection" width="45" align="center" fixed="left" />
									<el-table-column type="index" :index="index" label="序号" width="60" align="center"
										fixed="left" show-overflow-tooltip />
									<el-table-column prop="nick_name" label="领取人" show-overflow-tooltip min-width="120" />
									<el-table-column prop="headimg" label="头像" show-overflow-tooltip min-width="80">
										<template slot-scope="{row}">
											<el-image style="width:25px;height:25px;" :src="row.headimg" fit="cover" :preview-src-list="[row.headimg]">
												<div slot="error" class="image-slot">
													<i class="el-icon-picture-outline"></i>
												</div>
											</el-image>
										</template>
									</el-table-column>
									<el-table-column prop="activity_name" label="活动名称" show-overflow-tooltip min-width="120" />
									<el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="120" />
									<el-table-column prop="full_amount" label="领取金额(元)" show-overflow-tooltip min-width="120" />
									<el-table-column prop="less_amount" label="金额(元)" show-overflow-tooltip min-width="120" />
									<el-table-column prop="start_time" label="开始日期" show-overflow-tooltip min-width="160">
										<template slot-scope="{row}">{{ row.start_time*1000 | toDateString }}</template>
									</el-table-column>
									<el-table-column prop="end_time" label="结束日期" show-overflow-tooltip min-width="160">
										<template slot-scope="{row}">{{ row.end_time*1000 | toDateString }}</template>
									</el-table-column>
									<el-table-column prop="status_name" label="状态" show-overflow-tooltip min-width="120" />
								</template>
							</ele-data-table>
						</el-card>
				</el-dialog>
			</el-tab-pane>

			<el-tab-pane label="活动审核列表" name="second">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table1.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table1.reload()" @submit.native.prevent>
						<el-form-item label="活动类型:" label-width="76px">
							<el-select v-model="table1.where.type" placeholder="全部" clearable class="input150">
								<el-option label="零钱助力活动" value="1" />
								<el-option label="优惠满减活动" value="2" />
							</el-select>
						</el-form-item>
						<el-form-item label="商家姓名:" label-width="90px" class="w-input">
							<el-input v-model="table1.where.name" placeholder="请输入商家姓名" clearable />
						</el-form-item>
						<el-form-item label="商家手机号:" label-width="100px" style="width:260px">
							<el-input v-model="table1.where.phone" placeholder="请输入商家手机号" clearable />
						</el-form-item>
						<el-form-item label="店铺名称:" label-width="90px" class="w-input">
							<el-input v-model="table1.where.store_name" placeholder="请输入店铺名称" clearable />
						</el-form-item>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table1.where.pid" @change="handleChangeProv(table1.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table1.where.cid"
								@change="handleChangeCity(table1.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table1.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="left-btn">
							<el-button type="primary" @click="$refs.table1.reload()" icon="el-icon-search"
								class="ele-btn-icon">搜索</el-button>
							<el-button @click="(table1.where={})&&$refs.table1.reload()">重置</el-button>
						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table1" :config="table1" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="120" />
							<el-table-column prop="phone" label="商家手机号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="image" label="活动封面图" show-overflow-tooltip min-width="120" >
								<template slot-scope="{row}">
									<el-image style="width:25px;height:25px;" :src="row.image" fit="cover" :preview-src-list="[row.image]">
										<div slot="error" class="image-slot">
											<i class="el-icon-picture-outline"></i>
										</div>
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="name" label="活动名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="type_name" label="活动类型" show-overflow-tooltip min-width="120" />
							<el-table-column prop="start_time" label="开始时间" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="end_time" label="结束时间" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="day" label="领取有效时间(天)" show-overflow-tooltip min-width="140" />
							<el-table-column prop="audit" label="提交时间" show-overflow-tooltip min-width="120" />
							<el-table-column prop="audit_status" label="审核状态" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.audit_status == 1 ? '审核通过' :(row.audit_status == 2 ? '未审核' :'已驳回') }}</template>
							</el-table-column>
							<el-table-column label="操作" width="220px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link icon="el-icon-view" @click="handlecheckSelf(row)" type="primary"
										:underline="false">查看详情</el-link>
									<el-link v-if="row.audit_status==2 && permission.includes('sys:merchants_prop:pass')" icon="el-icon-circle-check" @click="checkActivity(row,1)" type="success" :underline="false"> 通过</el-link>
									<el-link v-if="row.audit_status==2 && permission.includes('sys:merchants_prop:reject')" icon="el-icon-circle-close" @click="checkActivity(row,3)" type="danger" :underline="false"> 驳回</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-card>
				<!-- 对话框：查看详情 -->
<!--				<el-dialog v-dialogDrag title="查看活动详情" :visible.sync="dialogTableVisibleCheck" @closed="editList1={}">-->
<!--					<el-form :model="editList1" label-width="180px" class="edit">-->
<!--						<el-card shadow="never">-->
<!--							<el-form-item label="活动类型:">-->
<!--								{{ editList1.type == 1 ? '零钱助力活动' :'优惠满减活动' }}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="用户类型:">-->
<!--								{{editList1.user_type == 2?'用户':(editList.user_type == 3?'司机':'全部')}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="开始时间：">-->
<!--								{{editList1.start_time}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="结束时间：">-->
<!--								{{editList1.end_time}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="有效时间:">-->
<!--								{{editList1.day}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="充值金额:">-->
<!--								{{editList1.recharge_money}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="助力档位设置:">-->
<!--							</el-form-item>-->
<!--							<el-form-item label="助力金额:">-->
<!--								{{editList1.day}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="助力人数:">-->
<!--								{{editList1.day}}-->
<!--							</el-form-item>-->
<!--						</el-card>-->
<!--					</el-form>-->
<!--				</el-dialog>-->
				
			</el-tab-pane>
			<el-tab-pane label="活动停止审核" name="three">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table7.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table7.reload()" @submit.native.prevent>
						<el-form-item label="活动类型:" label-width="76px">
							<el-select v-model="table7.where.type" placeholder="全部" clearable class="input150">
								<el-option label="零钱助力活动" value="1" />
								<el-option label="优惠满减活动" value="2" />
							</el-select>
						</el-form-item>
						<el-form-item label="商家姓名:" label-width="90px" class="w-input">
							<el-input v-model="table7.where.name" placeholder="请输入商家姓名" clearable />
						</el-form-item>
						<el-form-item label="商家手机号:" label-width="100px" style="width:260px">
							<el-input v-model="table7.where.phone" placeholder="请输入商家手机号" clearable />
						</el-form-item>
						<el-form-item label="店铺名称:" label-width="90px" class="w-input">
							<el-input v-model="table7.where.store_name" placeholder="请输入店铺名称" clearable />
						</el-form-item>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table7.where.pid" @change="handleChangeProv(table7.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table7.where.cid"
								@change="handleChangeCity(table7.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table7.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="left-btn">
							<el-button type="primary" @click="$refs.table7.reload()" icon="el-icon-search"
								class="ele-btn-icon">搜索</el-button>
							<el-button @click="(table7.where={})&&$refs.table7.reload()">重置</el-button>
						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table7" :config="table7" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="160" />
							<el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="120" />
							<el-table-column prop="phone" label="商家手机号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="image" label="活动封面图" show-overflow-tooltip min-width="120" >
								<template slot-scope="{row}">
									<el-image style="width:25px;height:25px;" :src="row.image" fit="cover" :preview-src-list="[row.image]">
										<div slot="error" class="image-slot">
											<i class="el-icon-picture-outline"></i>
										</div>
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="name" label="活动名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="type_name" label="活动类型" show-overflow-tooltip min-width="120" />
							<el-table-column prop="start_time" label="开始时间" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="end_time" label="结束时间" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
							</el-table-column>
							<el-table-column prop="day" label="领取有效时间(天)" show-overflow-tooltip min-width="140" />
							<el-table-column prop="audit" label="提交时间" show-overflow-tooltip min-width="120" />
							<el-table-column prop="audit_status" label="审核状态" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.audit_status == 1 ? '审核通过' :(row.audit_status == 2 ? '未审核' :'已驳回') }}</template>
							</el-table-column>
							<el-table-column label="操作" width="220px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link icon="el-icon-view" @click="handlecheckSelf(row)" type="primary"
										:underline="false">查看详情</el-link>
									<el-link v-if="row.audit_status==2 && permission.includes('sys:merchants_prop:tz_pass')" icon="el-icon-circle-check" @click="checkActivity(row,1)" type="success" :underline="false"> 通过</el-link>
									<el-link v-if="row.audit_status==2 && permission.includes('sys:merchants_prop:tz_reject')" icon="el-icon-circle-close" @click="checkActivity(row,3)" type="danger" :underline="false"> 驳回</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-card>
			</el-tab-pane>

			<el-tab-pane label="商家充值记录" name="third">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table2.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table2.reload()" @submit.native.prevent>
						<el-form-item label="充值方式:" label-width="76px">
							<el-select v-model="table2.where.type" placeholder="全部" clearable class="input150">
								<el-option label="支付宝" :value="2" />
								<el-option label="微信" :value="1" />
							</el-select>
						</el-form-item>
						<el-form-item label="商家姓名:" label-width="76px" class="w-input">
							<el-input v-model="table2.where.true_name" placeholder="请输入商家姓名" clearable />
						</el-form-item>
						<el-form-item label="商家手机号:" label-width="100px" style="width:260px">
							<el-input v-model="table2.where.phone" placeholder="请输入商家手机号" clearable />
						</el-form-item>
						<el-form-item label="店铺名称:" label-width="76px" class="w-input">
							<el-input v-model="table2.where.store_name" placeholder="请输入店铺名称" clearable />
						</el-form-item>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table2.where.pid" @change="handleChangeProv(table2.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table2.where.cid"
								@change="handleChangeCity(table2.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table2.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="left-btn">
							<el-button type="primary" @click="$refs.table2.reload()" icon="el-icon-search"
								class="ele-btn-icon">搜索</el-button>
							<el-button @click="(table2.where={})&&$refs.table2.reload()">重置</el-button>
						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table2" :config="table2" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="140" />
							<el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="120" />
							<el-table-column prop="phone" label="商家手机号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="money" label="充值金额(元)" show-overflow-tooltip min-width="120" />
							<el-table-column prop="type_name" label="充值方式" show-overflow-tooltip min-width="120" >
								<template slot-scope="scope">
									<span>{{scope.row.type==1?'微信':'支付宝'}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="create_time" label="充值时间" show-overflow-tooltip min-width="120" />
						</template>
					</ele-data-table>
				</el-card>
			</el-tab-pane>

			<el-tab-pane label="商家提现记录" name="fourth">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table3.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table3.reload()" @submit.native.prevent>
						<el-form-item label="提现方式:" label-width="90px" class="w-select">
							<el-select v-model="table3.where.type" placeholder="全部" clearable class="ele-fluid">
								<el-option v-for="option in storeactivityRecharge" :value="option.id" :key="option.id"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="审核状态:" label-width="90px" class="w-select">
							<el-select v-model="table3.where.status" placeholder="全部" clearable class="ele-fluid">
								<el-option v-for="option in storeactivityStatus" :value="option.id" :key="option.id"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="商家姓名:" label-width="90px" class="w-input">
							<el-input v-model="table3.where.true_name" placeholder="请输入商家姓名" clearable />
						</el-form-item>
						<el-form-item label="商家手机号:" label-width="100px" style="width:260px">
							<el-input v-model="table3.where.phone" placeholder="请输入商家手机号" clearable />
						</el-form-item>
						<el-form-item label="店铺名称:" label-width="90px" class="w-input">
							<el-input v-model="table3.where.store_name" placeholder="请输入店铺名称" clearable />
						</el-form-item>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table3.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table3.where.cid"
								@change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table3.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="left-btn">
							<el-button type="primary" @click="$refs.table3.reload()" icon="el-icon-search" class="ele-btn-icon search-margin">搜索</el-button>
							<el-button @click="(table3.where={})&&$refs.table3.reload()">重置</el-button>
						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table3" :config="table3" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="120">
								<template slot-scope="scope">
									{{scope.row.address}}
								</template>
							</el-table-column>
							<el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="100" />
							<el-table-column prop="phone" label="商家手机号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="money" label="提现金额(元)" show-overflow-tooltip min-width="120" />
							<el-table-column prop="type" label="提现方式" show-overflow-tooltip min-width="120" >
								<template slot-scope="scope">
									<span>{{scope.row.type==1?'微信':'支付宝'}}</span>
								</template>
							</el-table-column>
<!--							<el-table-column prop="status_name" label="审核状态" show-overflow-tooltip min-width="120" />-->
							<el-table-column prop="create_time" label="提现时间" show-overflow-tooltip min-width="160" />
							<el-table-column prop="confirm_time" label="审核时间" show-overflow-tooltip min-width="160" />
							<el-table-column label="操作" width="120px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<div  v-if="row.status == 1" >
									<el-link icon="el-icon-success" @click="handleIscheck(row)" type="primary"
										:underline="false" v-if="permission.includes('sys:merchants_prop:tx_pass')">通过</el-link>
									<el-link style="color:#ec4758" @click="handleIscheckReject(row)"
										icon="el-icon-error" type="primary" :underline="false" v-if="permission.includes('sys:merchants_prop:tx_reject')">驳回</el-link>
									</div>
									<div  v-else >
										<font v-if="row.status == 2" style="color: red">已驳回</font>
										<font v-else style="color: #2dc26b">{{row.status_name}}</font>
									</div>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>

					<!-- 编辑弹窗 -->
					<el-dialog v-dialogDrag :title="withdrawInfo.id?'填写驳回说明':''" :visible.sync="withdrawShow" ref="withdrawInfo" width="850px" @closed="withdrawInfo={}">
						<el-form :model="withdrawInfo" ref="withdrawInfo"  label-width="100px">
							<el-form-item label="驳回原因：" prop="note"  :rules="{
                    required: true, message: '驳回原因不能为空', trigger: 'blur'
                  }" >
								<el-input v-model="withdrawInfo.note" placeholder="请输入驳回原因"  :rows="5" type="textarea"  clearable/>
							</el-form-item>
						</el-form>
						<div slot="footer">
							<el-button @click="withdrawShow=false">取消</el-button>
							<el-button type="primary" @click="handleIscheckRejectSave">保存</el-button>
						</div>
					</el-dialog>
				</el-card>
			</el-tab-pane>

			<el-tab-pane label="商家退款记录" name="fifth">
				<el-card shadow="never">
					<!-- 搜索表单 -->
					<el-form :model="table4.where" class="ele-form-search" style="display:flex;flex-wrap: wrap;"
						@keyup.enter.native="$refs.table4.reload()" @submit.native.prevent>
						<el-form-item label="商家姓名:" label-width="76px">
							<el-input v-model="table4.where.name" placeholder="请输入商家姓名" class="input150" clearable />
						</el-form-item>
						<el-form-item label="商家手机号:" label-width="100px" style="width:260px">
							<el-input v-model="table4.where.phone" placeholder="请输入商家手机号" clearable />
						</el-form-item>
						<el-form-item label="店铺名称:" label-width="90px" class="w-input">
							<el-input v-model="table4.where.store_name" placeholder="请输入店铺名称" clearable />
						</el-form-item>
						<el-form-item label="省:" label-width="35px" class="w-170">
							<el-select v-model="table4.where.pid" @change="handleChangeProv(table.where.pid)"
								placeholder='请选择省' clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table4.where.cid"
								@change="handleChangeCity(table.where.cid),$forceUpdate()" placeholder='请选择市' clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县/区:" label-width="62px" class="w-200">
							<el-select v-model="table4.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item class="left-btn">
							<el-button type="primary" @click="$refs.table4.reload()" icon="el-icon-search"
								class="ele-btn-icon search-margin">搜索</el-button>
							<el-button @click="(table4.where={})&&$refs.table4.reload()">重置</el-button>
						</el-form-item>
					</el-form>
					<!-- 数据表格 -->
					<ele-data-table ref="table4" :config="table4" :choose.sync="choose" height="calc(100vh - 315px)"
						:stripe=true highlight-current-row>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="序号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="address" label="地区" show-overflow-tooltip min-width="140" />
							<el-table-column prop="true_name" label="商家姓名" show-overflow-tooltip min-width="120" />
							<el-table-column prop="phone" label="商家手机号" show-overflow-tooltip min-width="120" />
							<el-table-column prop="store_name" label="店铺名称" show-overflow-tooltip min-width="120" />
							<el-table-column prop="content" label="退款内容" show-overflow-tooltip min-width="120" />
							<el-table-column prop="money" label="退款金额(元)" show-overflow-tooltip min-width="120" />
							<el-table-column prop="create_time" label="退款时间" show-overflow-tooltip min-width="120">
							</el-table-column>
							<el-table-column prop="remark" label="备注" show-overflow-tooltip min-width="120" />
							<el-table-column label="操作" width="120px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link icon="el-icon-view" @click="activityDetails(row)" type="primary"
										:underline="false">查看详情</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-card>
				<!-- 对话框：查看详情 -->
				<el-dialog v-dialogDrag title="查看活动详情" :visible.sync="dialogTableVisibleSelfRefund" @closed="editList={}" :destroy-on-close="true" :lock-scroll="false">
					<el-form :model="editList" label-width="180px" class="edit">
						<el-card shadow="never">
							<div style="display: flex;">
								<div style="padding-right: 100px;border-right: 1px solid #c6c6c6;">
									<el-form-item label="活动类型：">
										{{ editList.type == 1 ? '零钱助力活动' :'优惠满减活动' }}
									</el-form-item>
									<el-form-item label="用户类型：">
										{{editList.user_type == 2?'用户':(editList.user_type == 3?'司机':'全部')}}
									</el-form-item>

										<el-form-item label="开始时间：">
											{{editList.start_time*1000 | toDateString}}
										</el-form-item>
										<el-form-item label="结束时间：">
											{{editList.end_time*1000 | toDateString}}
										</el-form-item>

										<el-form-item label="有效天数:">
											{{editList.day}}天
										</el-form-item>
									<el-form-item label="充值金额(元):">
										{{editList.recharge_money}}
									</el-form-item>
								</div>
								<div>
									<div v-if="editList.type ==1 ">
										<el-form-item label="助力档位设置:">
										</el-form-item>
										<div v-for="(items,index) in editList.storeActivityHelp" :key="index" style="display: flex;">
											<el-form-item label="助力金额(元):">
												{{items.amount}}
											</el-form-item>
											<el-form-item label="助力人数:">
												{{items.number}}
											</el-form-item>
										</div>
									</div>
									<div v-else-if="editList.type == 2">
										<el-form-item label="满减设置:">
										</el-form-item>
										<div  v-for="(items1,index1) in editList.storeActivityCoupon" :key="index1" style="display: flex;">
											<el-form-item label="满足金额:">
												{{items1.full_amount}}
											</el-form-item>
											<el-form-item label="减免金额:">
												{{items1.less_amount}}
											</el-form-item>
											<el-form-item label="赠送张数:">
												{{items1.number}}
											</el-form-item>
										</div>
									</div>
								</div>
							</div>
						</el-card>
					</el-form>
				</el-dialog>


<!--				<el-dialog v-dialogDrag title="查看活动详情" :visible.sync="dialogTableVisibleRefund" @closed="editList={}">-->
<!--					<el-form :model="editList" label-width="180px" class="edit">-->
<!--						<el-card shadow="never">-->
<!--							<el-form-item label="活动类型:">-->
<!--								{{ editList.type == 1 ? '零钱助力活动' :'优惠满减活动' }}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="用户类型:">-->
<!--								{{editList.user_type == 2?'用户':(editList.user_type == 3?'司机':'全部')}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="开始时间：">-->
<!--								{{editList.start_time}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="结束时间：">-->
<!--								{{editList.end_time}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="有效时间:">-->
<!--								{{editList.day}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="充值金额:">-->
<!--								{{editList.recharge_money}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="助力档位设置:">-->
<!--							</el-form-item>-->
<!--							<el-form-item label="助力金额:">-->
<!--								{{editList.day}}-->
<!--							</el-form-item>-->
<!--							<el-form-item label="助力人数:">-->
<!--								{{editList.day}}-->
<!--							</el-form-item>-->
<!--						</el-card>-->
<!--					</el-form>-->
<!--				</el-dialog>-->

			</el-tab-pane>

			<el-tab-pane label="活动教程" name="six" class="table6Caless">
					<el-form :model="table6.where" class="ele-form-search d-flexspabet"
						@keyup.enter.native="$refs.table6.reload()" @submit.native.prevent>
						<div>
							<el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small"
								v-if="permission.includes('sys:course:add')">添加活动教程</el-button>
						</div>
						<div class="d-flex">
							<el-form-item label="搜索课程标题:" label-width="100px" >
								<el-input v-model="table6.where.search" placeholder="请输入课程标题" clearable />
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="$refs.table6.reload()" icon="el-icon-search"
									class="ele-btn-icon ml-20">搜索</el-button>
								<el-button @click="(table6.where={})&&$refs.table6.reload()">重置</el-button>
							</el-form-item>
						</div>
					</el-form>
					<ele-data-table ref="table6" :config="table6" :choose.sync="choose" height="calc(100vh - 315px)"
						highlight-current-row :stripe=true>
						<template slot-scope="{index}">
							<el-table-column type="selection" width="45" align="center" fixed="left" />
							<el-table-column type="index" :index="index" label="编号" width="60" align="center"
								fixed="left" show-overflow-tooltip />
							<el-table-column prop="course_image" label="封面图：" show-overflow-tooltip width="240">
								<template slot-scope="{row}">
									<el-image style="width:80px;height:30px;" :src="row.course_image" fit="cover" :preview-src-list="[row.course_image]"></el-image>
								</template>
							</el-table-column>
							<!-- <el-table-column prop="type_name" label="显示版" show-overflow-tooltip min-width="120" /> -->
							<el-table-column prop="course_name" label="课程标题" show-overflow-tooltip min-width="120" />
							<el-table-column label="更新时间" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">{{ row.create_time}}</template>
							</el-table-column>
							<el-table-column label="内容" show-overflow-tooltip min-width="120">
								<template slot-scope="{row}">
									<div class="table6Html" v-html="row.course_content"></div>
								</template>
							</el-table-column>
							<el-table-column label="操作" width="220px" align="center" :resizable="false" fixed="right">
								<template slot-scope="{row}">
									<el-link @click="editWatch(row)" icon="el-icon-edit" type="primary"
										:underline="false" v-if="permission.includes('sys:course:edit')">查看</el-link>
									<el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false" v-if="permission.includes('sys:merchants_prop:jc_edit')">编辑</el-link>
									<el-link @click="activityremove(row)" slot="reference" icon="el-icon-delete" type="danger"
										:underline="false" v-if="permission.includes('sys:merchants_prop:jc_delete')">删除</el-link>
								</template>
							</el-table-column>
						</template>
					</ele-data-table>
				</el-tab-pane>
		</el-tabs>
		<!-- 对话框：查看详情 -->
		<el-dialog v-dialogDrag title="查看活动详情" :visible.sync="dialogTableVisibleSelf" @closed="editList={}" width="900px" :destroy-on-close="true" :lock-scroll="false">
			<el-form :model="editList" label-width="140px" class="edit">
				<el-card shadow="never">
							<el-form-item label="活动类型:">
								{{editList.type == 1 ? '零钱助力活动' :(editList.type == 2 ? '优惠满减活动' :'')}}
							</el-form-item>
							<el-form-item label="用户类型:">
								{{editList.user_type == 2?'用户':(editList.user_type == 3?'司机':'全部')}}
							</el-form-item>
							<el-form-item label="开始时间：">
								{{editList.start_time}}
							</el-form-item>
							<el-form-item label="结束时间：">
								{{editList.end_time}}
							</el-form-item>
							<el-form-item label="有效时间：">
								{{editList.day}}
							</el-form-item>
							<el-form-item label="充值金额(元)：">
								{{editList.recharge_money}}
							</el-form-item>
							<div v-if="editList.type == 1">
								<div style="margin: 0 0 20px 56px;">助力档位设置：</div>
								<div v-for="(items,index) in editList.storeActivityHelp" :key="index" style="display: flex;margin-left: 40px;">
									<el-form-item label="助力金额(元)：">
										{{items.amount}}
									</el-form-item>
									<el-form-item label="助力人数：">
										{{items.number}}
									</el-form-item>
								</div>
							</div>
							<div v-if="editList.type == 2">
								<div style="margin: 0 0 20px 56px;">满减设置：</div>
								<div  v-for="(items1,index1) in editList.storeActivityCoupon" :key="index1" style="display: flex;margin-left: 40px;">
									<el-form-item label="满足金额(元)：">
										{{items1.full_amount}}
									</el-form-item>
									<el-form-item label="减免金额：">
										{{items1.less_amount}}
									</el-form-item>
									<el-form-item label="赠送张数：">
										{{items1.number}}
									</el-form-item>
								</div>
							</div>
							<el-form-item label="活动封面图：">
								<el-image style="width: 100px; height: 100px" :src="editList.image" fit="cover" :preview-src-list="[editList.image]"></el-image>
							</el-form-item>
							<el-form-item label="活动内容：">
								<!-- <span>{{editList.details}}</span> -->
								<tinymce-editor v-model="editList.details" :init="editContent" />
							</el-form-item>
				</el-card>
			</el-form>
		</el-dialog>

		<!-- 添加活动教程 -->
		<el-dialog v-dialogDrag :title="activity.id?'编辑活动教程':'添加活动教程'" :visible.sync="showEdit" @closed="activity={}"
			:destroy-on-close="true" custom-class="ele-dialog-form" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="activity" ref="activity" :rules="editRules" label-width="100px">
					<el-form-item label="标题：" prop="course_name">
						<el-input v-model="activity.course_name" placeholder="" clearable />
					</el-form-item>
					<el-form-item label="封面图：" prop="course_image">
						<uploadImage :limit="1" v-model="activity.course_image"></uploadImage>
					</el-form-item>
					<el-form-item label="图文内容：">
						<tinymce-editor v-model="activity.course_content" :init="editContent" />
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showEdit=false">取消</el-button>
				<el-button type="primary" @click="save">保存</el-button>
			</div>
		</el-dialog>
		<!--查看活动教程 -->
		<el-dialog v-dialogDrag title="查看课程详情" :visible.sync="showEditWatch" @closed="watchForm={}" :destroy-on-close="true"
			custom-class="ele-dialog-form" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="watchForm" ref="watchForm" label-width="100px">
					<el-form-item label="课程标题：" prop="title">
						{{watchForm.course_name}}
					</el-form-item>
					<el-form-item label="创建时间：" prop="title">
						{{ watchForm.create_time}}
					</el-form-item>
					<el-form-item label="课程图片：">
						<el-image style="width: 100px; height: 100px" :src="watchForm.course_image" fit="cover" :preview-src-list="[watchForm.course_image]"></el-image>
					</el-form-item>
					<el-form-item label="图文内容：" prop="title">
						<tinymce-editor v-model="watchForm.course_content" :init="editContent" />
					</el-form-item>
				</el-form>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
	import TinymceEditor from '@/components/TinymceEditor'
	import uploadImage from '@/components/uploadImage';
	import {mapGetters} from "vuex";

	export default {
		name: "SysUser",
		components: {TinymceEditor,uploadImage},
		data() {
			return {
				table: {
					url: '/storeactivity/index', //商家自营活动
					where: {audit_status:1}
				},
				table1: {
					url: '/storeactivity/index', //活动审核列表
					where: {audit_status:2}
				},
				table2: {
					url: '/storeactivity/recharge_list', //商家充值记录
					where: {}
				},
				table3: {
					url: '/storeactivity/withdraw_list', //商家提现记录
					where: {}
				},
				table4: {
					url: '/storeactivity/return_list', //商家退款记录
					where: {}
				},
				table5: {
					url: '/storeactivity/receive_list', //商家退款记录
					where: {activity_id:0}
				},
				table6: {
					url: '/course/getStoreMarketingCourseList', //活动教程
					where: {search:''}
				},
				table7: {
					url: '/storeactivity/getStopList', //停止审核列表
					where: {audit_status:2}
				},
				withdrawShow:false,
				withdrawInfo:{},
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				activity:{}, //活动弹出表单
				editList: {},
				editList1: {},
				editList2: {},
				editList3: {},
				editList4: {},
				checkForm: {},
				checkForm1: {},
				checkForm2: {},
				checkForm3: {},
				checkForm4: {},
				dialogTableVisibleSelf: false,
				dialogTableVisibleSelfGet: false,
				dialogTableVisibleSelfRefund: false,
				dialogTableVisibleCheck: false,
				dialogTableVisibleCheckGet: false,
				dialogTableVisibleEdit: false,
				dialogTableVisibleUrgent: false,
				dialogTableVisibleRefund: false,
				showEditWatch: false,//查看课程详情
				watchForm: [],
				provArr: [],
				cityArr: [],
				districtArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				activeName: 'first',
				row: [{
						state: '开启'
					},
					{
						state: '关闭'
					}
				],
				storeactivityType: [{
					id: 1,
					name: '零钱助力活动'
				}, {
					id: 2,
					name: '优惠满减活动'
				}],
				storeactivityStatus: [{
					id: 1,
					name: '待审核'
				}, {
					id: 2,
					name: '拒绝'
				}, {
					id: 3,
					name: '已通过'
				}],
				storeactivityRecharge: [{
					id: 1,
					name: '微信'
				}, {
					id: 2,
					name: '支付宝'
				}],
				showEdit:false,//活动教程弹框 开关
				editRules: { // 添加活动教程表单验证规则
					course_name: [{
						required: true,
						message: '请输入课程标题',
						trigger: 'blur'
					}],
					course_image: [{
						required: true,
						message: '请选择封面图',
						trigger: 'blur'
					}],
				},
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})
		},

		computed: {
			...mapGetters(["permission"]),
			editContent() {
				return {
					menubar: false,
					// file_picker_callback: this.file_picker_callback,
					skin_url: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/ui/oxide-dark' :
						'/tinymce/skins/ui/oxide',
					content_css: this.$store.state.theme.theme === 'dark' ? '/tinymce/skins/content/dark/content.css' :
						'/tinymce/skins/content/default/content.css'
				};
			}
		},
		mounted() {},
		methods: {
			/* 删除活动教程列表 */
			activityremove(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的活动教程吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/course/deleteStoreMarketingCourse', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table6.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					this.$confirm('确定要删除选中的活动教程吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/course/deleteStoreMarketingCourse', {
							id: row.id
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table6.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
			/* 编辑活动教程列表 */
			edit(row) {
				this.showEdit = true;
				this.activity = row;
			},
			/* 查看活动教程列表 */
			editWatch(row) {
				this.showEditWatch = true;
				this.watchForm = row;
			},
			// 添加活动教程保存
			save() {
				this.$refs['activity'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						//console.log(this.activity)
						let url='';
						if(this.activity.id){
							url='/course/editStoreMarketingCourse'
						}else{
							url='/course/addStoreMarketingCourse'
						}
						this.$http.post(url, this.activity).then(res => {
							loading.close();
							//console.log(res)
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table6.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			dropClick(command, row) {
				if (command === 'handlecheck') { // 查看详情
					this.handlecheck()
				} else if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'soldOut') { //下架
					this.handleUrgent(row)
				} else if (command === 'getRecord') { //查看领取记录
					this.handleEdit(row)
				}else if(command ==='underline'){
					this.activityUnderline(row)
				}else if(command === 'activityDelete'){
					this.activityDelete(row);
				}
			},
			/***查看商家自营活动详情**/
			handlecheckSelf(row) {
				this.dialogTableVisibleSelf = true
				this.editList = row;
			},
			/***查看商家自营领取列表**/
			handlecheckSelfGet(row) {
				this.dialogTableVisibleSelfGet = true;
				this.table5.where.activity_id = row.id;
				this.$refs.table5.reload();
			},
			activityDetails(row){
				this.$http.get('/storeactivity/activity_details?id='+row.activity_id).then(res => {
					if (res.data.code === 0) {
						this.dialogTableVisibleSelfRefund = true;
						this.editList = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},

			/***查看活动审核列表详情**/
			handlecheckActivity(row) {
				this.dialogTableVisibleCheck = true
				this.editList1 = row;
			},
			/***查看活动审核领取列表**/
			handlecheckActivityGet(row) {
				this.dialogTableVisibleCheckGet = true
				this.editList1 = row;
			},
			/***查看商家自营活动详情**/
			handlecheckRefund(row) {
				this.dialogTableVisibleRefund = true
				this.editList = row;
			},
			activityUnderline(row){
				this.$confirm('您确定下线此活动嘛?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('storeactivity/update_activity_status', {
						id: row.id,
						status: 2
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({type: 'success', message: res.data.msg});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			checkActivity(row,status){
				var status_str = ''
				if(status == 1){
					status_str = '通过';
				}else if(status == 3){
					status_str = '驳回';
				}
				this.$confirm('您确定'+status_str+'此活动嘛?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('storeactivity/check_activity_status', {
						id: row.id,
						status: status
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({type: 'success', message: res.data.msg});
							this.$refs.table1.reload();
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			activityDelete(row){
				this.$confirm('您确定删除此活动嘛?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('storeactivity/update_activity_status', {
						id: row.id,
						status: 3
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({type: 'success', message: res.data.msg});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},

			// 审核商家提现记录：通过
			handleIscheck(row) {
				this.$confirm('您确定通过该审核申请嘛?', '提示', {
					type: 'warning'
				}).then(() => {
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('storeactivity/withdraw_audit', {
						id: row.id,
						status: 3
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: '审核通过'
							});
							this.activeName="fourth";
							this.$refs.table3.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				})
			},
			// 审核商家提现记录：驳回

			handleIscheckReject(row){
				this.withdrawInfo = row;
				this.withdrawShow =true;
			},

			handleIscheckRejectSave() {

				this.$refs['withdrawInfo'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('storeactivity/withdraw_audit', {
							id: this.withdrawInfo.id,
							status: 2,
							note: this.withdrawInfo.note
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.withdrawShow=false;
								this.$message({
									type: 'success',
									message: '拒绝审核通过'
								});
								this.activeName='fourth';
								this.$refs.table3.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});

					} else {
						return false;
					}
				});



			},
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			pause_start(row) {
				if (row.is_pause == 1) {
					this.$confirm('确定暂停活动吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/driver/face_close', {
							id: row.aid
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '暂停成功'
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				} else {
					this.$confirm('确定开始活动?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/driver/face_start', {
							id: row.aid
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '开始成功'
								});
								this.$refs.table.reload();
								this.pauseStart = '暂停'
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
			switch_start(row) {
				if (row.is_switch == 1) {
					this.$confirm('确定关闭活动吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/driver/face_close', {
							id: row.aid
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '关闭成功'
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				} else {
					this.$confirm('确定启用活动?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/driver/face_start', {
							id: row.aid
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '启用成功'
								});
								this.$refs.table.reload();
								this.switchStart = '关闭'
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			}
		}
	}
</script>

<style scoped>
	.table6Caless{
		padding: 16px;
		background-color: #fff;
	}
	.table6Html{
		max-height: 23px;
	}
	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.ele-body {
		padding: 15px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	/deep/.el-tabs__item {
		padding: 0 20px !important;
		font-size: 15px;
	}

	/deep/.el-tabs__nav-scroll {
		background: white !important;
		padding-top: 10px;
	}

	.drop-down-first {
		width: 150px;
		margin-right: 10px;
	}

	.drop-down-second {
		width: 150px;
	}

	.search-margin {
		margin-left: 10px;
	}

	.user-info-tabs>>>.el-tabs__nav-wrap {
		padding-left: 20px;
	}

	.w-select {
		width: 200px;
	}

	.w-input {
		width: 250px;
	}

	.left-btn {
		margin-left: 30px;
	}

	.redColor {
		color: red
	}
	/deep/.el-dialog{
		margin-top: 60px !important;
		width: 70%;
	}
</style>
